import { useSession } from 'next-auth/client';
import Router from 'next/router';

export function useUser() {
	const [session, loading] = useSession();
	return {
		isLoggedIn: !!session?.user,
		user: session?.user,
		fetchingUser: loading,
	};
}

export function withUser(
	WrappedComponent,
	{ redirectTo, redirectIfFound } = {}
) {
	// eslint-disable-next-line react/display-name
	return (props) => {
		const { user, isLoggedIn, fetchingUser } = useUser();

		if (
			typeof window !== 'undefined' &&
			// If redirectTo is set, redirect if the user was not found.
			((redirectTo && !redirectIfFound && !isLoggedIn) ||
				// If redirectIfFound is also set, redirect if the user was found
				(redirectIfFound && isLoggedIn))
		) {
			Router.push(redirectTo);
		}

		return (
			<WrappedComponent
				{...props}
				isLoggedIn={isLoggedIn}
				user={user}
				fetchingUser={fetchingUser}
			/>
		);
	};
}
