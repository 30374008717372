import { Avatar as AntAvatar } from 'antd';

export default function Avatar({
	src,
	name,
	size = 24,
	clickable,
	background,
	onClick,
	...props
}) {
	clickable = onClick || !!onClick;
	return (
		<AntAvatar
			{...props}
			src={src}
			size={size}
			className={`avatar ${clickable ? ' clickable' : ''}`}
			children={!src && name ? name[0] : null}
			style={{
				fontSize: '2.6rem',
				lineHeight: 'normal',
				transform: 'none',
				backgroundColor: background || 'var(--text-color)',
			}}
			onClick={onClick}
		/>
	);
}
