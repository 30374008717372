import firebase from './Firebase';

const LOG_EVENTS = process.env.NODE_ENV === 'production';

export default class Analytics {
	static Event = {
		STRONG_LINKS: 'strong_links',
		SHARE_LINK: 'share_link',
		SHOUTOUT_REACTION: 'shoutout_reaction',
		SHOUTOUT_SUBMITTED: 'shoutout_submitted',
		SHOUTOUT_CLICKED: 'shoutout_submitted',
		USER_CONFIG: 'user_config',
		INPUT_SUGGESTIONS: 'input_suggestions',
		INPUT_SUGGESTIONS_DIALOG: 'input_suggestions_dialog',
		LOGIN: 'login',
		LOGOUT: 'logout',
		SIGNUP: 'signup',
		CALENDAR_ACCESS: 'calendar_access',
	};

	static init() {
		if (!LOG_EVENTS) return;

		firebase?.analytics();
		Analytics.pageview(window.location.pathname);
	}

	static setUser(user) {
		if (!LOG_EVENTS || typeof window === 'undefined') return;

		if (user) {
			if (user.id) firebase?.analytics().setUserId(user.id, { global: true });
			const properties = {};
			if (user.company) properties.company = user.company;
			firebase?.analytics().setUserProperties(properties, { global: true });
		} else {
			firebase?.analytics().setUserId(null, { global: true });
			firebase?.analytics().setUserProperties({}, { global: true });
		}
	}

	static pageview(url) {
		if (!LOG_EVENTS) return;

		firebase?.analytics().setCurrentScreen(url);
		firebase?.analytics().logEvent('screen_view', {
			screen_name: url,
		});
	}

	static event(type, event) {
		if (!LOG_EVENTS) return;

		firebase?.analytics().logEvent(type, event);
	}
}
