import { default as NextLink } from 'next/link';

export default function Link({ href, shallow, ...props }) {
	return (
		<NextLink href={href || '/'} shallow={shallow} passHref prefetch={false}>
			<a className={props.className} onClick={props.onClick}>
				{props.children}
			</a>
		</NextLink>
	);
}
